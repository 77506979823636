import { Header, Reward20171101 } from 'components/policy';

const PolicyReward = () => {
  return (
    <>
      <Header />
      <Reward20171101 />
    </>
  );
};

export default PolicyReward;
